.Contact-page__body {
  margin-top: var(--main-top-margin);
  background-color: var(--main-background-color);
  height: 100vh;
  padding-top: 5%;
}

.Contact-page__form__main {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;

  font-family: var(--main-font);
  border-style: solid;
  border-color: var(--header-color);
  border-width: 1px;
  padding: 2%;
  width: 80vw;
}

label {
  color: var(--header-color);
}

input,
textarea,
button {
  font-family: var(--main-font);
  font-size: 1em;
  width: 100%;
}

textarea {
  height: 200px;
}

.Contact-page__message-received__success-message {
  color: white;
}

.Contact-page__message-received {
  background-color: black;
  margin-left: 5%;
  color: white;
}

.Contact-page__error p {
  height: 5vh;
  color: red;
  margin-left: 5%;
}

.Contact-page__form__send-message-link:hover {
  cursor: pointer;
}

.Contact-page__form__send-message-link {
  margin-left: 5%;
}

@media only screen and (min-width: 800px) {
  .Contact-page__body {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
  .Contact-page__form__main {
    width: 45%;
  }
}
