.Home-page__body {
  background-color: var(--main-background-color);
  height: 95vh;
}

.Home-page__body__main-profile-photo {
  width: 200px;
  height: 30%;
}

.Home-page__body__content {
  margin-left: 5%;
  margin-right: 5%;
}

@media only screen and (min-width: 800px) {
  .Home-page__body__main-profile-photo {
    width: 300px;
    height: 40%;
    margin-top: 5%;
  }
}
