.News-page__main {
  background-color: var(--main-background-color);
  margin-top: 40px;
}

@media only screen and (min-width: 800px) {
  .News-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}
