.Top-bar {
  background-color: var(--top-nav-background-color);
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  display: grid;
  grid-template-columns: auto auto auto auto 40px;
  grid-template-rows: 40px;
  grid-template-areas: ". . . . right-menu";
}

.Top-bar__burger {
  grid-area: right-menu;
  position: absolute;
  right: 10px;
}

.Top-bar__burger:hover {
  cursor: pointer;
}

.Top-bar__nav {
  display: none;
}

.Top-bar__nav__link {
  text-decoration: none;
  padding: 2%;
  display: block;
}

.Top-bar__nav__link:hover {
  background-color: var(--menu-highlight-color);
}

.Top-bar__nav__link:hover {
  cursor: pointer;
}

.Nav__slide-menu {
  position: absolute;
  top: 40px;
  width: 100vw;
  left: 0px;
  display: block;
  background-color: var(--top-nav-background-color);
  text-align: center;
}

.link-active {
  font-weight: bolder;
}

.Nav__menu-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

@media only screen and (min-width: 800px) {
  .Top-bar__nav {
    display: block;
  }
  .Top-bar__burger {
    display: none;
  }

  .Nav__slide-menu {
    display: none;
  }

  .Top-bar__nav__link {
    padding: 2%;
    display: inline;
  }
}
