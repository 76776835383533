@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css);
:root {
  --color-dark-green-0: #233;
  --color-white: #ffffff;
  --color-highlight-color: #41474b;
  --color-header-green: #4caf50;
  --background-color: #152f1a;
  --background-gradient: -moz-radial-gradient(top left, #152f1a, #4caf51);

  --main-font: Consolas, "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", Monaco, "Courier New", "monospace";
  --hashtag-font: "Roboto Mono", monospace;
  --font-text-color: var(--color-white);
  --top-nav-background-color: var(--color-dark-green-0);
  --menu-highlight-color: var(--color-highlight-color);
  --header-color: var(--color-header-green);
  --main-background-color: black;
  --main-top-margin: 40px;
  --desktop-margin-left: 20%;
  --desktop-margin-right: 20%;
}

li {
  font-family: Consolas, "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", Monaco, "Courier New", "monospace";
  font-family: var(--main-font);
  color: #ffffff;
  color: var(--color-white);
}

body {
  background-color: #152f1a;
  background-color: var(--background-color);
  background-image: -moz-radial-gradient(top left, #152f1a, #4caf51);
  background-image: var(--background-gradient);
}

.Top-bar__burger {
  color: #ffffff;
  color: var(--color-white);
  font-size: 40px;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: Consolas, "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", Monaco, "Courier New", "monospace";
  font-family: var(--main-font);
}
p,
a {
  color: #ffffff;
  color: var(--font-text-color);
  font-family: Consolas, "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", Monaco, "Courier New", "monospace";
  font-family: var(--main-font);
}

.Top-bar {
  background-color: var(--top-nav-background-color);
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  display: grid;
  grid-template-columns: auto auto auto auto 40px;
  grid-template-rows: 40px;
  grid-template-areas: ". . . . right-menu";
}

.Top-bar__burger {
  grid-area: right-menu;
  position: absolute;
  right: 10px;
}

.Top-bar__burger:hover {
  cursor: pointer;
}

.Top-bar__nav {
  display: none;
}

.Top-bar__nav__link {
  text-decoration: none;
  padding: 2%;
  display: block;
}

.Top-bar__nav__link:hover {
  background-color: var(--menu-highlight-color);
}

.Top-bar__nav__link:hover {
  cursor: pointer;
}

.Nav__slide-menu {
  position: absolute;
  top: 40px;
  width: 100vw;
  left: 0px;
  display: block;
  background-color: var(--top-nav-background-color);
  text-align: center;
}

.link-active {
  font-weight: bolder;
}

.Nav__menu-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

@media only screen and (min-width: 800px) {
  .Top-bar__nav {
    display: block;
  }
  .Top-bar__burger {
    display: none;
  }

  .Nav__slide-menu {
    display: none;
  }

  .Top-bar__nav__link {
    padding: 2%;
    display: inline;
  }
}

.Bio-page__body__content__bio-photo {
  width: 200px;
  height: 35%;
}

.Bio-page__main {
  background-color: var(--main-background-color);
  margin-top: var(--main-top-margin);
}

.Bio-page__body__content {
  margin-right: 5%;
  margin-left: 5%;
}
@media only screen and (min-width: 800px) {
  .Bio-page__body__content__bio-photo {
    width: 320px;
    height: 500px;
    margin-top: 5%;
  }
  .Bio-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}

h2 {
  color: var(--header-color);
}

.bio-content {
  height: 100vh;
}

.Contact-page__body {
  margin-top: var(--main-top-margin);
  background-color: var(--main-background-color);
  height: 100vh;
  padding-top: 5%;
}

.Contact-page__form__main {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;

  font-family: var(--main-font);
  border-style: solid;
  border-color: var(--header-color);
  border-width: 1px;
  padding: 2%;
  width: 80vw;
}

label {
  color: var(--header-color);
}

input,
textarea,
button {
  font-family: var(--main-font);
  font-size: 1em;
  width: 100%;
}

textarea {
  height: 200px;
}

.Contact-page__message-received__success-message {
  color: white;
}

.Contact-page__message-received {
  background-color: black;
  margin-left: 5%;
  color: white;
}

.Contact-page__error p {
  height: 5vh;
  color: red;
  margin-left: 5%;
}

.Contact-page__form__send-message-link:hover {
  cursor: pointer;
}

.Contact-page__form__send-message-link {
  margin-left: 5%;
}

@media only screen and (min-width: 800px) {
  .Contact-page__body {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
  .Contact-page__form__main {
    width: 45%;
  }
}

.Project-blurb__main__body__project-stack__stack-tag {
  font-family: var(--hashtag-font);
  font-size: small;
  margin-bottom: 2%;
  margin-top: 2%;
}

.Project-blurb__main__body__alt-link {
  font-family: var(--hashtag-font);
  color: var(--color-header-green);
}

.Project-blurb__main__body__app-title {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--color-header-green);
}
.project-screen-caps-image {
  width: 90%;
  height: auto;
  display: block;
}

h3 > a {
  color: var(--color-header-green);
}

@media only screen and (min-width: 800px) {
  .project-screen-caps-image {
    display: inline-block;
    margin-right: 2%;
  }
}

.Dev-page__body {
  margin-top: var(--main-top-margin);
  background-color: var(--main-background-color);
  height: 100%;
}

.Dev-page__body__content {
  margin-left: 5%;
  margin-right: 5%;
}

@media only screen and (min-width: 800px) {
  .Dev-page__body {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}

.Home-page__body {
  background-color: var(--main-background-color);
  height: 95vh;
}

.Home-page__body__main-profile-photo {
  width: 200px;
  height: 30%;
}

.Home-page__body__content {
  margin-left: 5%;
  margin-right: 5%;
}

@media only screen and (min-width: 800px) {
  .Home-page__body__main-profile-photo {
    width: 300px;
    height: 40%;
    margin-top: 5%;
  }
}

.Home-page__main {
  background-color: var(--main-background-color);
  margin-top: var(--main-top-margin);
}

.updated_footer {
  padding: 10px;
  text-align: center;
}
@media only screen and (min-width: 800px) {
  .Home-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}

h2 {
  color: var(--header-color);
}


h2 {
  color: var(--header-color);
}

.score-image {
  width: 168px;
  height: 225px;
}

.Composition-item__audio-link {
  font-family: var(--hashtag-font);
  color: var(--color-header-green);
}

.composition-title {
  color: var(--color-header-green);
}

.Music-quick-nav__main {
  font-family: var(--main-font);
  display: block;
}

a {
  text-decoration: none;
}

.Music-page__main {
  background-color: var(--main-background-color);
  margin-top: var(--main-top-margin);
}

.Music-page__body__content {
  margin-right: 5%;
  margin-left: 5%;
}

html {
  scroll-behavior: smooth;
}

@media only screen and (min-width: 800px) {
  .Music-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}

.News-page__main {
  background-color: var(--main-background-color);
  margin-top: 40px;
}

@media only screen and (min-width: 800px) {
  .News-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}

