.Home-page__main {
  background-color: var(--main-background-color);
  margin-top: var(--main-top-margin);
}

.updated_footer {
  padding: 10px;
  text-align: center;
}
@media only screen and (min-width: 800px) {
  .Home-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}
