.Music-page__main {
  background-color: var(--main-background-color);
  margin-top: var(--main-top-margin);
}

.Music-page__body__content {
  margin-right: 5%;
  margin-left: 5%;
}

html {
  scroll-behavior: smooth;
}

@media only screen and (min-width: 800px) {
  .Music-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}
