.score-image {
  width: 168px;
  height: 225px;
}

.Composition-item__audio-link {
  font-family: var(--hashtag-font);
  color: var(--color-header-green);
}

.composition-title {
  color: var(--color-header-green);
}
