@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");
:root {
  --color-dark-green-0: #233;
  --color-white: #ffffff;
  --color-highlight-color: #41474b;
  --color-header-green: #4caf50;
  --background-color: #152f1a;
  --background-gradient: -moz-radial-gradient(top left, #152f1a, #4caf51);

  --main-font: Consolas, "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", Monaco, "Courier New", "monospace";
  --hashtag-font: "Roboto Mono", monospace;
  --font-text-color: var(--color-white);
  --top-nav-background-color: var(--color-dark-green-0);
  --menu-highlight-color: var(--color-highlight-color);
  --header-color: var(--color-header-green);
  --main-background-color: black;
  --main-top-margin: 40px;
  --desktop-margin-left: 20%;
  --desktop-margin-right: 20%;
}

li {
  font-family: var(--main-font);
  color: var(--color-white);
}

body {
  background-color: var(--background-color);
  background-image: var(--background-gradient);
}

.Top-bar__burger {
  color: var(--color-white);
  font-size: 40px;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: var(--main-font);
}
p,
a {
  color: var(--font-text-color);
  font-family: var(--main-font);
}
