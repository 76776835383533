.Project-blurb__main__body__project-stack__stack-tag {
  font-family: var(--hashtag-font);
  font-size: small;
  margin-bottom: 2%;
  margin-top: 2%;
}

.Project-blurb__main__body__alt-link {
  font-family: var(--hashtag-font);
  color: var(--color-header-green);
}

.Project-blurb__main__body__app-title {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--color-header-green);
}
.project-screen-caps-image {
  width: 90%;
  height: auto;
  display: block;
}

h3 > a {
  color: var(--color-header-green);
}

@media only screen and (min-width: 800px) {
  .project-screen-caps-image {
    display: inline-block;
    margin-right: 2%;
  }
}
