.Dev-page__body {
  margin-top: var(--main-top-margin);
  background-color: var(--main-background-color);
  height: 100%;
}

.Dev-page__body__content {
  margin-left: 5%;
  margin-right: 5%;
}

@media only screen and (min-width: 800px) {
  .Dev-page__body {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}
