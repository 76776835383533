.Bio-page__body__content__bio-photo {
  width: 200px;
  height: 35%;
}

.Bio-page__main {
  background-color: var(--main-background-color);
  margin-top: var(--main-top-margin);
}

.Bio-page__body__content {
  margin-right: 5%;
  margin-left: 5%;
}
@media only screen and (min-width: 800px) {
  .Bio-page__body__content__bio-photo {
    width: 320px;
    height: 500px;
    margin-top: 5%;
  }
  .Bio-page__main {
    margin-left: var(--desktop-margin-left);
    margin-right: var(--desktop-margin-right);
  }
}
